import React, { useEffect, useState } from 'react';
import { Checkbox, Chip, ClickAwayListener, Divider, fade, FormControlLabel, IconButton, InputBase, LinearProgress, makeStyles, Switch, TextField, useTheme, withStyles } from '@material-ui/core';
import { ReactComponent as Check } from "../../assets/icons/check-icon.svg";
// import attachmentList from './attachments.json';
import { Close, InsertEmoticon, PlayCircleOutline, Replay, Search } from '@material-ui/icons';
import UserProfileCard from '../UserProfileCard/UserProfileCard';
import { connect } from 'react-redux';
import { getContacts } from '../../ducks/contacts';
import { getContactSearchResult } from '../../ducks/search';
import { useSnackbar } from 'notistack';
import { ChaseonsSkeleton } from '../Arena/ReactionsDialog';
import { postArena } from '../../ducks/arena';
import Axios from 'axios';
import { getGroupsList, uploadFilesToS3 } from '../../ducks/common';
import Poll from '../../assets/icons/survey-1.svg'
import noPreview from '../../assets/images/nopreview.png';
import _, { uniq } from 'underscore';
import { PublishTo } from './PublishTo';
import { Picker } from 'emoji-mart';
import { getLeaguesListNew, setTagPeopleList } from '../../ducks/game';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#F1F2F5',
        position: 'relative',
        // display: 'flex',
        // minHeight: 500,
        minHeight: 'calc(100vh - 64px)',
        // maxWidth: 600,
        maxHeight: 'calc(100vh - 64px)',
        // width: '70%',
        width: '100%',
        //     -webkit-transition: 'width 1s ease-in-out'
        // -moz-transition:width 1s ease-in-out;
        // -o-transition: width 1s ease-in-out;
        transition: 'width 0.5s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            // maxWidth: 400,
            maxHeight: 400,
            // minHeight: 400,
            minHeight: 'calc(100vh - 64px)',
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: 400,
            minHeight: '100vh'
        },
    },
    titleRoot: {
        marginRight: 5,
        backgroundColor: '#FFF',
        fontSize: 18,
        color: '#262626',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& p': {
            margin: '0px 50px'
        }
    },
    content: {
        padding: 20,
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 171px)'
    },
    postPreview: {
        paddingBottom: 20,
        display: 'flex',
        alignItems: 'center',
        '& img': {
            width: 82,
            height: 82,
            borderRadius: 4,
            objectFit: 'contain',
            backgroundColor: '#7f7f7f17'
        },
        '& video': {
            width: 82,
            height: 82,
            borderRadius: 4
        },
        '& .inputRoot': {
            // padding: '5px 20px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none !important',
            }
        },
        '& .textLength': {
            fontSize: 11,
            // paddingLeft: 20
        }
    },
    tagRoot: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 0',
        position: 'relative',
        '& p': {
            cursor: 'pointer',
            margin: 0,
            paddingBottom: 3,
            fontSize: 16,
            width: 'fit-content',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        '& span': {
            fontSize: 12
        }
    },
    tagUsersListRoot: {
        width: '40%',
        backgroundColor: '#FFF',
        padding: '0 20px',
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            width: 'calc(100% - 40px)',
            minHeight: '100vh',
            zIndex: 1
        },
        '& .title': {
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            '& p': {
                fontSize: 18,
            },
            '& span': {
                cursor: 'pointer',
                color: '#112386',
                fontSize: 14,
                '&:hover': {
                    textDecoration: 'underline'
                }

            }
        }
    },
    search: {
        position: 'relative',
        borderRadius: 10,
        backgroundColor: '#F1F2F5',
        '&:hover': {
            backgroundColor: fade('#F1F2F5', 0.75),
        },
        width: 'auto',
        border: "1px solid #B3B3B3",

        marginRight: '25px',
        // marginLeft: theme.spacing(1),
        // maxWidth: 250,
        // minWidth: 175,
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#4D4D4D'
    },
    searchInputRoot: {
        color: 'inherit',
        width: '100%',
    },
    searchInputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height: 20,
        fontSize: 12,
        borderRadius: '10px'

    },
    usersList: {
        height: '70vh',
        marginTop: 20,
        overflowY: 'auto',
        width: 'calc(100% + 16px)',
        // overflowX: 'hidden',
        maxWidth: '100%'
    },
    avatarStyles: {
        width: 28,
        height: 28,
        fontSize: 16
    },
    profileName: {
        fontSize: 13,
        fontWeight: 500,
    },
    secondaryText: {
        fontSize: 10
    },
    playIcon: {
        color: '#fff',
        position: 'absolute',
        left: 'calc(50% - 18px)',
        top: 'calc(50% - 18px)'
    },
    failedContent: {
        '& p': {
            color: '#FF3B30',
            margin: '7px 0 0',
        },
        '& span': {
            fontSize: 14,
        },
        '& button': {
            padding: 8
        }
    },
    textField: {
        borderRadius: 6,
        padding: 12,
        // width: 350,
        marginTop: 6,
        paddingRight: '50px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '30px',
        },

    },
    textCounter: {
        margin: '8px 8px 0px',
        fontSize: '13px !important ',
        textAlign: 'right'
    },

    //emoji picker styles
    emojiPicker: {
        position: "absolute",
        bottom: 95,
        right: 0,
        padding: "0 15px",
        zIndex: 1,
        "& .emoji-mart": {
            minWidth: "50% !important",
            float: 'right',
            [theme.breakpoints.down('sm')]: {
                width: "auto !important",
            },
        },
        "& .emoji-mart-scroll": {
            height: 170
        }
    },
    emojiButtn: {
        position: 'absolute',
        right: 0,
        bottom: '42px',
        paddingRight: '0px',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '0px'
        },
    },
    chiparea: {
        margin: "5px",
        color: "#58595B",
        backgroundColor: `${theme.palette.primary.main}1A`,
        borderRadius: '5px'

    },
    groupDiv: {
        backgroundColor: '#ffffff',
        border: '1px solid #d9d9d9',
        borderRadius: '5px',
        minHeight: '70px',
        maxHeight: '80px',
        overflowY: 'auto',
        padding: '5px',
        color: '#4e5254',
        margin: '5px',
    },
    selectedGroupCount: {
        backgroundColor: `${theme.palette.primary.main}1A`,
        textAlign: 'center',
        padding: '4px',
        borderRadius: '5px',
    },
    disableText: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: "#79797B !important"
        }
    }
}));

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 14,
        padding: 0,
        display: 'flex',
        overflow: 'visible',
        marginRight: 10
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,

    },
    checked: {},
}))(Switch);

let emojiPicker = null;
const CancelToken = Axios.CancelToken;
const TagUsers = ({ attachments, cropType, pollDetails, linkDetails = null, thumbnailUrl = "", getContacts, getContactSearchResult, getGroups, postArena, uploadFilesToS3, onSubmit, keycloak: { tokenParsed: { preferred_username, realm_access } }, createArena: { open, arena = {}, stepType, attachment, postDetails }, getLeaguesListNew, nudgeText, setTagPeopleList, tagPeopleList }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [text, setText] = useState(nudgeText ? nudgeText : '');
    const [isFeatured, setIsFeatured] = useState(true);
    const [isLearing, setIsLearning] = useState(false)
    const [loading, setLoading] = useState(false);
    const [openTagUsersList, setOpenTagUsersList] = useState(false);
    const [openPublishTo, setOpenPublishTo] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [taggedUsers, setTaggedUsers] = useState([]);
    const [query, setQuery] = useState('');
    const [source, setSource] = useState(CancelToken.source());
    const [uploadedAttachments, setUploadedAttachments] = useState([]);
    const [posting, setPosting] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [notificationTitle, setNotificationTitle] = useState("");
    const [leaguesList, setLeaguesList] = useState([]);
    const [selectedLeagues, setSelectedLeagues] = useState([]);
    const [isAll, setIsAll] = useState(false);
    const [showEmojis, setShowEmojis] = useState(false);
    const [showTitleEmojis, setShowTitleEmojis] = useState(false);
    const theme = useTheme();
    const [leagueSearch, setLeagueSearch] = useState('');
    const [groupsList, setGroupsList] = useState([]);
    const [copyOfLeaguesList, setCopyOfLeaguesList] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [notificationCheck, setNotificationCheck] = useState(true);

    useEffect(() => {
        setLoading(true);
        getContacts({ fromArena: true, onGetContactsSuccessCB, onGetContactsErrorCB });
        getGroups({
            onSuccessCB: (result) => {
                setLoading(false);
                setGroupsList([...result])
            },
            onErrorCB: (error) => {
                setLoading(false);
                setGroupsList([]);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch Groups.', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
        getLeaguesListNew({
            params: { limit: 5000, page: 1 },
            onSuccessCB: (result) => {
                setLoading(false);
                const SpecificLeague = result.filter(obj => obj._id === postDetails?.details?.league)
                if (SpecificLeague.length > 0) {
                    setLeaguesList(result.map(obj => ({ ...obj, checked: obj._id === postDetails?.details?.league })));
                    setCopyOfLeaguesList(result.map(obj => ({ ...obj, checked: obj._id === postDetails?.details?.league })));

                    setSelectedLeagues(SpecificLeague)
                    setIsAll(false);

                }
                else {
                    setLeaguesList(result.map(obj => ({ ...obj, checked: false })));
                    setCopyOfLeaguesList(result.map(obj => ({ ...obj, checked: false })));


                }
            },
            onErrorCB: (error) => {
                setLoading(false);
                setLeaguesList([]);
                setCopyOfLeaguesList([]);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : 'Failed to fetch Groups.', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })

        return () => {
            source.cancel('User Cancelled Uploading.');
            setSource(CancelToken.source());
        }
    }, []);

    const tagPeopleListDub = _.uniq(JSON.parse(JSON.stringify([...taggedUsers || [], ..._.pluck(tagPeopleList, 'username')])));
    const onGetContactsSuccessCB = (result) => {
        setLoading(false);
        // const tagPeopleListDub = _.uniq(JSON.parse(JSON.stringify([...taggedUsers || [], ..._.pluck(tagPeopleList, 'username')])));
        const filteredResult = result.filter(item => item.username !== preferred_username);
        setTagPeopleList(_.uniq([...usersList, ...tagPeopleList].filter(obj => tagPeopleListDub.includes(obj.username), user => user.username)));
        if (taggedUsers.length > 0) {
            const existingTaggedUsers = taggedUsers.map(obj => obj.username);
            setUsersList(filteredResult.map(obj => ({ ...obj, checked: tagPeopleListDub.includes(obj.username) })));
        }
        else {
            // setUsersList(filteredResult);
            setUsersList(filteredResult.map(obj => ({ ...obj, checked: tagPeopleListDub.includes(obj.username) })));
        }
    }

    const onGetContactsErrorCB = (error) => {
        setUsersList([]);
        setTagPeopleList([]);
        enqueueSnackbar(`Failed to fetch Chaseons.`, {
            variant: 'error',
            preventDuplicate: true
        });
        setLoading(false);
    }

    const handleChangeQuery = (e) => {
        let value = e.target.value;
        setQuery(value);
        if (value.length === 0) {
            setLoading(true);
            getContacts({ fromArena: true, onGetContactsSuccessCB, onGetContactsErrorCB });
        }
    }
    const handleKeyPress = e => {
        if (e.nativeEvent.keyCode === 13 && query.length > 0) {
            if (!e.nativeEvent.shiftKey) {
                e.preventDefault();
                handleSearch(query);
                // return false;
            }
        }
    }

    const handleSearch = (query) => {
        if (query.length > 0) {
            setLoading(true);
            getContactSearchResult({ query, onSearchSuccessCB: onGetContactsSuccessCB, onSearchErrorCB: onGetContactsErrorCB });
        }
        else {
            setUsersList([]);
        }
    }

    const handleCheckbox = (index, user) => {
        let usersListDup = JSON.parse(JSON.stringify(usersList));
        let dummyTagpeopList = tagPeopleList.map(el => {
            if (!el.checked) el.checked = true;
            return el;
        })
        let tagPeopleListDub = JSON.parse(JSON.stringify(dummyTagpeopList));
        usersListDup[index].checked = !usersListDup[index].checked;
        setUsersList([...usersListDup]);
        tagPeopleListDub.map(el => {
            if (el.username === user.username && el.checked) el.checked = !el.checked;
            return el;
        })
        setTagPeopleList(_.uniq([...usersListDup, ...tagPeopleListDub].filter(user => user?.checked), usr => usr.username));

        // let taggedUsersDup = JSON.parse(JSON.stringify(taggedUsers));
        // const index = taggedUsersDup.findIndex(user => user.username === userObj.username);
        // if (index >= 0) {
        //     taggedUsersDup.splice(index, index + 1);
        // }
        // else {
        //     taggedUsersDup.push(userObj);
        // }
        // setTaggedUsers([...taggedUsersDup]);
    }

    const handleGroupsCheckbox = (index, isAll) => {
        if (searchResults.length > 0) {
            if (index === 'ALL') {
                let groupsListDup = JSON.parse(JSON.stringify(leaguesList));
                let searchResultsDup = JSON.parse(JSON.stringify(searchResults));
                if (!isAll) {
                    searchResultsDup = searchResultsDup.map(obj => ({ ...obj, checked: true }));
                    setSearchResults([...searchResultsDup]);
                }
                else {
                    searchResultsDup = searchResultsDup.map(obj => ({ ...obj, checked: false }));
                    setSearchResults([...searchResultsDup]);


                }
                setIsAll(!isAll);
                let playertobeAdded = [...groupsListDup]
                    .map((ele) => {
                        if ((searchResultsDup.map(checkedEmployee => checkedEmployee._id).includes(ele._id))) {
                            if (!isAll) {
                                ele.checked = true

                            }
                            else {
                                ele.checked = false

                            }
                        }

                        return ele;
                    })
                setLeaguesList([...playertobeAdded])


            }
            else {
                let groupsListDup = JSON.parse(JSON.stringify(leaguesList));
                let searchResultsDup = JSON.parse(JSON.stringify(searchResults));
                const i = groupsListDup.findIndex(
                    (obj) => obj._id === searchResults[index]._id
                );
                groupsListDup[i].checked = !groupsListDup[i].checked;
                searchResultsDup[index].checked = !searchResultsDup[index].checked;
                const checkedCount = searchResultsDup.filter(obj => obj.checked)?.length;
                if (checkedCount > 0 && checkedCount !== searchResults?.length) {
                    setIsAll(false);
                }
                else if (checkedCount === searchResults?.length) {
                    setIsAll(true);

                }
                setLeaguesList([...groupsListDup]);
                setSearchResults([...searchResultsDup]);
            }

        }
        else
            if (index === 'ALL') {
                let groupsListDup = JSON.parse(JSON.stringify(leaguesList));
                if (!isAll) {
                    groupsListDup = groupsListDup.map(obj => ({ ...obj, checked: true }));
                    setLeaguesList([...groupsListDup]);

                }
                else {
                    groupsListDup = groupsListDup.map(obj => ({ ...obj, checked: false }));
                    setLeaguesList([...groupsListDup]);

                }
                setIsAll(!isAll);
            }
            else {
                let groupsListDup = JSON.parse(JSON.stringify(leaguesList));
                groupsListDup[index].checked = !groupsListDup[index].checked;
                const checkedCount = groupsListDup.filter(obj => obj.checked)?.length;
                if (checkedCount > 0 && checkedCount !== leaguesList?.length) {
                    setIsAll(false);
                }
                else if (checkedCount === leaguesList?.length)
                    setIsAll(true);
                setLeaguesList([...groupsListDup]);

            }
    }

    const handleCancelGroupChip = (group, index) => {
        let groupsListDup = JSON.parse(JSON.stringify(leaguesList));
        let uncheckedItem = groupsListDup.find(obj => obj._id === group._id)
        uncheckedItem.checked = !uncheckedItem.checked
        setLeaguesList([...groupsListDup]);
        setCopyOfLeaguesList([...groupsListDup]);
        const resultedGroups = selectedLeagues.filter((obj) => obj._id !== group._id)
        setSelectedLeagues([...resultedGroups])

    }

    const handleSubmitGroups = () => {
        const selectedLeagues = leaguesList.filter(obj => obj.checked);
        setSelectedLeagues(uniq(selectedLeagues, x => x._id));
        setOpenPublishTo(false);
    }

    const onUploadSuccessCB = (result, file) => {
        // finalAttachmentsCount++;
        if (result.url) {
            setUploadedAttachments(prevState => ([...prevState, { url: result.url, thumbnailUrl: result.thumbnailUrl || thumbnailUrl, mimeType: file.mimeType, index: file.index }]));
            // setUploadErrorText('');  
        }
        // else setUploadErrorText('Failed');
        // if (finalAttachmentsCount === attachmentsCount) {
        //     setUploading(false);
        // }
    }

    const onUploadErrorCB = (result, file) => {
        setIsFailed(true);
        source.cancel('User Cancelled Uploading.');
        setSource(CancelToken.source());
        // console.log('onUploadErrorCB ', result.response);
        if (result.response) {
            enqueueSnackbar(`Failed to Upload ${file.name}.`, {
                variant: 'error'
            });
        }
    }

    const handleRetry = () => {
        setIsFailed(false);
        if (uploadedAttachments.length === attachments.length) {
            submitPost();
        }
        else
            handleSubmitPost();
    }

    useEffect(() => {
        // && uploadedAttachments.length === attachments.length
        if (uploadedAttachments.length > 0) {
            submitPost();
        }
    }, [uploadedAttachments]);

    const submitPost = async () => {
        const finalSelectedLeagues = groupsList.filter(group => selectedLeagues.map(league => league._id).includes(group.leaugeGroupId))
        let groups = [];
        if (isFeatured) groups.push('FEATURED');
        if (isLearing) groups.push('LEARNING');
        let requestObj = {
            // category: isFeatured ? 'FEATURED' : 'REGULAR',
            groups: groups,
            type: (uploadedAttachments.length || linkDetails) ? 'IMAGE' : (pollDetails) ? 'SURVEY' : 'TEXT',
            text: text,
            ratio: cropType.value === 'SQUARE' ? "" : `${cropType.ratio}`,
            pushNotificationEnabled: notificationCheck,
            pushNotificationText: notificationCheck ? notificationMsg : null,
            pushNotificationTitle: notificationCheck ? notificationTitle : null,
            // tags: taggedUsers
            userGroups: isAll && selectedLeagues.length === leaguesList.length ? [] : finalSelectedLeagues.map(obj => obj.id),
            contest_props: postDetails
        };
        if (uploadedAttachments.length) requestObj.attachments = [...uploadedAttachments].sort((a, b) => (a.index > b.index) ? 1 : -1);
        if (pollDetails?._id) {
            requestObj.attachments = [{ url: pollDetails._id, mimeType: 'SURVEY', thumbnailUrl: pollDetails.question?.title }]
            requestObj.ratio = pollDetails.question?.options?.filter(obj => obj.label).length
        }
        if (linkDetails && linkDetails.url) {
            const response = await fetch(`https://rlp-proxy.herokuapp.com/v2?url=${linkDetails.url}`);
            const json = await response.json();
            const details = json.metadata;
            requestObj.attachments = [{ mimeType: 'LINK', url: details.url, siteName: details?.hostname || '', siteDescription: details.description || '', thumbnailUrl: linkDetails?.image || '' }]
        }
        if (tagPeopleList.length) requestObj.tags = tagPeopleList.map(obj => obj.username);

        setPosting(true);
        if (!isAll && finalSelectedLeagues.length === 0) {
            setPosting(false);
            enqueueSnackbar(`Please pick any league from the publish to`, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else if (requestObj.type === 'TEXT' && requestObj.text.length == 0) {
            setPosting(false);
            enqueueSnackbar(`Text is Empty.`, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else if ((requestObj?.type === 'TEXT' && (/["']/.test(requestObj?.text))) || (/["']/.test(requestObj?.pushNotificationText)) || (/["']/.test(requestObj?.pushNotificationTitle))) {
            setPosting(false);
            enqueueSnackbar(`Inverted commas are not allowed.`, {
                variant: 'error',
                preventDuplicate: true
            });
        }
        else {
            setPosting(true);
            postArena({ requestObj, onSuccessCB, onErrorCB });
            setTagPeopleList([]);
        }

    }

    const srcToFile = (src, fileName, mimeType) => {
        return (fetch(src)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
        );
    }

    const handleSubmitPost = () => {
        if (attachments.length > 0 && selectedLeagues.length !== 0) {
            attachments.forEach((item, i) => {
                srcToFile(
                    item.url, item.fileName, item.fileType
                ).then((file) => {
                    // console.log('attachements ', file);
                    let formData = new FormData();
                    formData.append("file", file);
                    setPosting(true);
                    uploadFilesToS3({ formData, onUploadSuccessCB, onUploadErrorCB, file: { mimeType: item.mimeType, index: i, name: item.fileName }, cancelToken: source.token });
                });
            });
        }
        else {
            submitPost();
        }
    }

    const onSuccessCB = (result) => {
        // console.log(result);
        enqueueSnackbar(`Post Created successfully.`, {
            variant: 'success',
            preventDuplicate: true
        });
        onSubmit();
        setPosting(false);
        // props.handleOnNewPost(new Date().getTime());
        // props.setLoading(false);
        // props.tagChaseOns({ postId: result.id, requestObj: { user_ids: selectedChaseOns.map(user => user.username) }, onTagChaseOnsSuccessCB, onTagChaseOnsErrorCB });
    }

    const onErrorCB = (error) => {
        setIsFailed(true);
        console.log('onErrorCB ', error);
        enqueueSnackbar((error.response && error.response.data) ? error.response.data.error : `Failed to post Arena.`, {
            variant: 'error',
            preventDuplicate: true
        });
        // props.setLoading(false);
    }

    const handleTextChange = (e) => {
        setText(e.target.value);
    }

    const handleOnMsgChange = (e, field) => {
        const val = e.target.value
        if (val.length <= 200) {
            (field === 'message') ? setNotificationMsg(val) : setNotificationTitle(val);
        }
    };

    const handleSubmitTags = () => {
        const unCheckedUsers = usersList.filter(obj => !obj.checked).map(obj => obj.username);
        const totalTaggedUsers = [...taggedUsers.filter(obj => !unCheckedUsers.includes(obj.username)), ...usersList.filter(obj => obj.checked)];
        setTaggedUsers(uniq(totalTaggedUsers, x => x.username));
        setOpenTagUsersList(false);
    }

    // ---emoji sec adding --
    const handleShowEmojis = (field) => {
        if (field === 'message') setShowEmojis(true)
        else if (field === 'title') setShowTitleEmojis(true)
    };
    const addEmoji = (e, field) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach(el => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        (field === 'message') ? setNotificationMsg(notificationMsg + emoji) : setNotificationTitle(notificationTitle + emoji);
    };
    const handleClickAway = (e) => {
        e.preventDefault();
        if (showEmojis) setShowEmojis(false)
        else if (showTitleEmojis) setShowTitleEmojis(false)
    };



    return (
        <div style={{ backgroundColor: '#FFF', width: '100%', display: 'flex' }}>
            <div className={classes.root} style={{ width: openTagUsersList && '60%' }}>
                <div className={classes.titleRoot}>
                    <p>New Post</p>
                    {
                        (!isFailed && !posting) &&
                        <IconButton title="Post" className="checkIcon" onClick={() => handleSubmitPost()}>
                            <Check />
                        </IconButton>
                    }
                </div>
                <div className={classes.content}>
                    <div className={classes.postPreview}>
                        {attachments[0] ? (attachments[0].mimeType === 'IMAGE' ?
                            <img src={attachments[0].url} alt="image" />
                            :
                            attachments[0].mimeType === 'PDF' ?
                                <img src={attachments[0].thumbnailUrl} alt="image" />
                                :
                                <div style={{ position: 'relative' }}>
                                    <video src={attachments[0].url} autoPlay={false} />
                                    <PlayCircleOutline
                                        className={classes.playIcon}
                                        fontSize="large"
                                    />
                                </div>
                        )
                            : (pollDetails?._id) ? <img src={Poll} alt="image" />
                                : (linkDetails && <img src={linkDetails?.image || noPreview} alt="image" />)
                        }
                        <div className={classes.disableText} style={{ width: '100%', padding: '5px 20px' }}>
                            {posting ?
                                [
                                    isFailed ?
                                        <div className={classes.failedContent}>
                                            <p>FAILED</p>
                                            <span>Please try again...</span>
                                            <IconButton title="Retry" onClick={() => handleRetry()}>
                                                <Replay />
                                            </IconButton>
                                        </div>
                                        :
                                        <>
                                            <p>POSTING...</p>
                                            <LinearProgress color="primary" />
                                        </>

                                ]
                                :
                                <>
                                    <TextField
                                        id="outlined"
                                        fullWidth
                                        placeholder="Write a caption which describes better about your Post..."
                                        multiline
                                        // variant="outlined"
                                        rows={2}
                                        rowsMax={6}
                                        inputProps={{ maxLength: 500 }}
                                        value={text}
                                        onChange={(e) => handleTextChange(e)}
                                        InputProps={{ classes: { root: 'inputRoot' } }}
                                        disabled={nudgeText ? true : false}
                                    />
                                    <span className="textLength">{text.length}/500</span>
                                </>
                            }
                        </div>
                    </div>
                    <Divider variant="fullWidth" component="div" />
                    <div className={classes.tagRoot}>
                        <p onClick={() => { setOpenTagUsersList(!openTagUsersList); setOpenPublishTo(false); }}>Tag people</p>
                        <span>{_.uniq(tagPeopleList, tagPeopleList => tagPeopleList.username).map(obj => obj.name).join(', ') || 'Type in names of people whom you want to tag'}</span>
                    </div>
                    <Divider variant="fullWidth" component="div" />
                    {
                        realm_access.roles.includes('manage_featured_posts') &&
                        <div className={classes.tagRoot} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <p onClick={() => setIsFeatured(!isFeatured)}>Add to Featured</p>
                                <span>Publish as a highlighted content</span>
                            </div>
                            <AntSwitch checked={isFeatured} onChange={() => setIsFeatured(!isFeatured)} name="checkedC" />
                        </div>

                    }
                    {
                        realm_access.roles.includes('manage_learning_content') &&
                        <>
                            <Divider variant="fullWidth" component="div" />
                            <div className={classes.tagRoot} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>
                                    <p onClick={() => setIsLearning(!isLearing)}>Add to LearnHub</p>
                                    <span>Publish as a learning content</span>
                                </div>
                                <AntSwitch checked={isLearing} onChange={() => setIsLearning(!isLearing)} name="checkedL" />
                            </div>
                        </>
                    }
                    <Divider variant="fullWidth" component="div" />
                    <div className={classes.tagRoot}>
                        <p onClick={() => { setOpenPublishTo(!openPublishTo); setOpenTagUsersList(false); }}>Publish to <span style={{ color: '#E50808', fontSize: 'large' }}>*</span></p>
                        < div className={classes.groupDiv} >
                            {
                                selectedLeagues.map((group, index) => (
                                    <Chip label={group.name} className={classes.chiparea}
                                        onDelete={() => handleCancelGroupChip(group, index)}
                                    />

                                ))

                            }
                        </div>
                        <span style={{ marginTop: '5px' }}>This post will be published to <span className={classes.selectedGroupCount}>{selectedLeagues.length} Leagues</span></span>


                    </div>
                    {
                        realm_access.roles.includes('admin') &&
                        <>
                            {/* <div style={{ paddingTop: 15 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={notificationCheck}
                                            color="primary"
                                            name="Enable Notification"
                                            size="small"
                                            onChange={(event) => setNotificationCheck(event.target.checked)}
                                            inputProps={{ "aria-label": "primary checkbox" }}
                                        />}
                                    label="Enable Notification"
                                    labelPlacement="start"
                                    style={{ marginLeft: 0 }}
                                />
                            </div> */}
                            {isFeatured &&
                                <>
                                    <Divider variant="fullWidth" component="div" />
                                    <div className={classes.tagRoot}>
                                        <p style={{ pointerEvents: 'none' }}>Notification Title</p>
                                        <TextField
                                            id="outlined"
                                            fullWidth
                                            placeholder="Type text here..."
                                            multiline
                                            variant="outlined"
                                            rows={1}
                                            rowsMax={3}
                                            value={notificationTitle}
                                            onChange={(e) => handleOnMsgChange(e, 'title')}
                                            InputProps={{ classes: { root: classes.textField } }}
                                        />
                                        <p className={classes.emojiButtn}>
                                            <IconButton
                                                onClick={() => handleShowEmojis('title')}
                                                className={classes.iconButtonEmoji}
                                                aria-label="menu"
                                            >
                                                <InsertEmoticon />
                                            </IconButton>
                                        </p>
                                        {showTitleEmojis && (
                                            <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
                                                <span
                                                    className={classes.emojiPicker}
                                                    ref={el => (emojiPicker = el)}
                                                >
                                                    <Picker
                                                        onSelect={e => addEmoji(e, 'title')}
                                                        emojiTooltip={true}
                                                        title=""
                                                        color={theme.palette.primary.main}
                                                        exclude={['flags']}
                                                    />
                                                </span>
                                            </ClickAwayListener>
                                        )}
                                        <span className={classes.textCounter}>{(notificationTitle ? notificationTitle.length : 0) + '/' + 200}</span>
                                    </div>
                                    <Divider variant="fullWidth" component="div" />
                                    <div className={classes.tagRoot}>
                                        <p style={{ pointerEvents: 'none' }}>Notification Message</p>
                                        <TextField
                                            id="outlined"
                                            fullWidth
                                            placeholder="Type text here..."
                                            multiline
                                            variant="outlined"
                                            rows={1}
                                            rowsMax={3}
                                            value={notificationMsg}
                                            onChange={(e) => handleOnMsgChange(e, 'message')}
                                            InputProps={{ classes: { root: classes.textField } }}
                                        />
                                        <p className={classes.emojiButtn}>
                                            <IconButton
                                                onClick={() => handleShowEmojis('message')}
                                                className={classes.iconButtonEmoji}
                                                aria-label="menu"
                                            >
                                                <InsertEmoticon />
                                            </IconButton>
                                        </p>
                                        {showEmojis && (
                                            <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
                                                <span
                                                    className={classes.emojiPicker}
                                                    ref={el => (emojiPicker = el)}
                                                >
                                                    <Picker
                                                        onSelect={e => addEmoji(e, 'message')}
                                                        emojiTooltip={true}
                                                        title=""
                                                        color={theme.palette.primary.main}
                                                        exclude={['flags']}
                                                    />
                                                </span>
                                            </ClickAwayListener>
                                        )}
                                        <span className={classes.textCounter}>{(notificationMsg ? notificationMsg.length : 0) + '/' + 200}</span>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            {
                openTagUsersList &&
                <div className={classes.tagUsersListRoot}>
                    <div className="title" >
                        <p>Tag people</p>
                        <span onClick={() => handleSubmitTags()}>Done</span>
                    </div>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <Search />
                        </div>
                        <InputBase
                            placeholder="Search for people who are tagged in the post"
                            classes={{
                                root: classes.searchInputRoot,
                                input: classes.searchInputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            value={query}
                            onChange={handleChangeQuery}
                            onKeyUp={handleKeyPress}
                        />
                    </div>
                    <div className={classes.usersList}>
                        {
                            loading ?
                                [0, 1, 2, 3].map(i => <ChaseonsSkeleton secondary={true} key={i} />)
                                :
                                usersList.length > 0 ? usersList.map((user, i) => (
                                    <div key={i}>
                                        <div key={i} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <UserProfileCard
                                                key={`tag-${i}`}
                                                name={user.name}
                                                username={user.username}
                                                avatarUrl={user.photo_url}
                                                avatarStyles={classes.avatarStyles}
                                                profileNameStyles={classes.profileName}
                                                secondaryTextStyles={classes.secondaryText}
                                                style={{ margin: '12px 2px', alignItems: 'center' }}
                                                // follower={true}
                                                showFollowStatus={true}
                                            // onProfileClick={() => history.push(getProfileUrl(item.userId))}
                                            />
                                            <Checkbox style={{ display: 'inline-table' }} checked={user.checked} onChange={() => handleCheckbox(i, user)} color="primary" />
                                        </div>
                                        <Divider style={{ margin: '0 12px 0 38px' }} variant="fullWidth" component="div" />
                                    </div>
                                ))
                                    : <p style={{ fontSize: 14, textAlign: 'center' }}>No Chaseons found</p>
                        }
                    </div>
                </div>
            }
            {
                openPublishTo &&
                <PublishTo classes={classes} loading={loading} leaguesList={leaguesList} isAll={isAll} handleGroupsCheckbox={handleGroupsCheckbox} handleSubmitGroups={handleSubmitGroups} setLeaguesList={setLeaguesList} leagueSearch={leagueSearch} setLeagueSearch={setLeagueSearch} copyOfLeaguesList={copyOfLeaguesList} setSearchResults={setSearchResults} searchResults={searchResults} />
            }
        </div >
    )
}

const mapStateToProps = (state, props) => ({
    user: state.user,
    keycloak: state.keycloak,
    postDetails: state.arena.createArena.postDetails,
    createArena: state.arena.createArena,
    tagPeopleList: state.game.tagPeopleList
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        uploadFilesToS3: (options) => dispatch(uploadFilesToS3(options)),
        postArena: (options) => dispatch(postArena(options)),
        getContacts: (options) => dispatch(getContacts(options)),
        getContactSearchResult: (options) => dispatch(getContactSearchResult(options)),
        getGroups: (options) => dispatch(getGroupsList(options)),
        getLeaguesListNew: (options) => dispatch(getLeaguesListNew(options)),
        setTagPeopleList: (options) => dispatch(setTagPeopleList(options))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagUsers);