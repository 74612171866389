import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { setLoading } from "../../ducks/loading";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ReactComponent as ReactLike } from "../../assets/icons/likeicon.svg";
import { ReactComponent as ReactLikeActive } from "../../assets/icons/likeactive.svg";
import { ReactComponent as ReactDisLike } from "../../assets/icons/dislike.svg";
import { ReactComponent as ReactDisLikeActive } from "../../assets/icons/dislikeactive.svg";
import { ReactComponent as ReactMenuIcon } from "../../assets/icons/menuIcon.svg";
import { ReactComponent as ReactShareIcon } from "../../assets/icons/shareIcon.svg";
import { updateNudgeNotify } from "../../ducks/nudge";
import { useSnackbar } from "notistack";
import { openCreateArenaDialog } from "../../ducks/arena";
import CreatePost from "../ArenaComponents/CreatePost";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    buzzCard: {
        width: '100%',
        minHeight: 45,
        padding: '20px 16px',
        borderRadius: 8,
        color: '#FFFFFF',
        boxShadow: '0px 0px 4px 1px rgba(161, 161, 161, 0.25)',
        display: 'flex',
        justifyContent: 'space-between',
        '& .notification': {
            '& p': {
                fontSize: 14,
                color: 'rgba(79, 80, 85, 1)',
                margin: 0,
                whiteSpace: 'break-spaces',
                '& span': {
                    color: 'rgba(76, 158, 235, 1)'
                }
            },
            '& .date': {
                color: 'rgba(165, 172, 184, 1)',
                paddingTop: 5
            }
        },
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    notifyIcons: {
        display: 'flex',
        // minWidth: 250,
        '& .shareMenu': {
            fontSize: 14,
            display: 'inline-flex',
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 8,
            padding: '5px 12px',
            height: 'fit-content',
            marginRight: 6,
            alignItems: 'center',
            cursor: 'pointer',
            '& span': {
                fontSize: 14,
                paddingLeft: 5,
                color: 'rgba(79, 80, 85, 0.9)'
            },
            '& .Icon': {
                cursor: 'pointer'
            }
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: 20,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 20,
        },
    },

}));

const BuzzNotificationCard = ({ buzz, updateNudgeNotify, setLoading, openCreateArenaDialog, buzzData, index, setBuzzData }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

    }, [])

    // ----------updating notification like dislike---------
    const updateBuzzNotification = (nudzeId, reaction) => {
        updateNudgeNotify({ nudzeId, reqObj: { reaction: reaction } });
    }

    const handleReactionClick = (e, index, buz_id, reaction) => {
        let copy_BuzzData = JSON.parse(JSON.stringify(buzzData))
        if (reaction !== 'LIKE') {
            copy_BuzzData[index].reaction = 'LIKE';
            setBuzzData(copy_BuzzData);
            updateBuzzNotification(buz_id, 'LIKE')
        }
        else if (reaction !== 'DISLIKE') {
            copy_BuzzData[index].reaction = 'DISLIKE';
            setBuzzData(copy_BuzzData);
            updateBuzzNotification(buz_id, 'DISLIKE')
        }
    }

    // -------share to wall as atext -  
    const shareToWall = (e, text) => {
        openCreateArenaDialog({ open: true, arena: {}, stepType: 'tagUser', attachment: [], buzzText: text })
    }


    return (
        <div className={classes.root}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.buzzCard}>
                            <div className="notification">
                                <p> {buzz?.text} </p>
                                {/* <span># Youcandoit</span> */}
                                <p className="date">{buzz?.created_time}</p>
                            </div>
                            <div className={classes.notifyIcons}>
                                <div className="shareMenu">
                                    {
                                        buzz?.reaction === 'LIKE' ?
                                            <> <ReactLikeActive className="Icon" style={{ width: 20, height: 20 }} /></>
                                            : <ReactLike className="Icon" onClick={(e) => handleReactionClick(e, index, buzz?._id, 'DISLIKE')} style={{ width: 20, height: 20 }} />
                                    }
                                </div>
                                <div className="shareMenu">
                                    {
                                        buzz?.reaction === 'DISLIKE' ?
                                            <> <ReactDisLikeActive className="Icon" style={{ width: 20, height: 20 }} /></>
                                            : <ReactDisLike className="Icon" onClick={(e) => handleReactionClick(e, index, buzz?._id, 'LIKE')} style={{ width: 20, height: 20 }} />
                                    }
                                </div>
                                {/* <div className="shareMenu" onClick={(e) => shareToWall(e, buzz?.text)}>
                                    <ReactShareIcon style={{ width: 20, height: 20 }} /> <span>Share to wall</span>
                                </div> */}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <CreatePost postingType='Nudge' />
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        updateNudgeNotify: (options) => dispatch(updateNudgeNotify(options)),
        openCreateArenaDialog: (options) => dispatch(openCreateArenaDialog(options))

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuzzNotificationCard));