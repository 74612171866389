import React, { useState } from 'react';
import { setLoading } from '../../ducks/loading';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import queryString from "query-string";
import Axios from 'axios';
// import mql from '@microlink/mql'
// import Microlink from '@microlink/react'
import noPreview from '../../assets/images/nopreview.png';

// const metascraper = require('metascraper')
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        width: '100%',
        transition: 'width 0.5s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            // maxWidth: 400,
            maxHeight: 400,
            // minHeight: 400,
            minHeight: 'calc(100vh - 64px)',
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: 400,
            minHeight: '100vh'
        },
    },
    titleRoot: {
        // marginRight: 5,
        backgroundColor: '#FFF',
        fontSize: 18,
        color: '#262626',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        borderBottom: '1px solid #e0dce0',
        '& p': {
            margin: '0px 50px'
        },
        '& .submitBtn': {
            cursor: 'pointer',
            color: '#112386',
            fontSize: 14,
            marginRight: 16,
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    content: {
        padding: 30,
        height: '100%',
        overflowY: 'auto'
    },
    textField: {
        borderRadius: 6
    },
    autocompleteRoot: {
        borderRadius: 6,
        // padding: '5px 7px !important',
        // width: 280
    },
    previewRoot: {
        width: '40%',
        backgroundColor: '#FFF',
        padding: '0 20px',
        borderLeft: '1px solid #e0dce0',
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            width: 'calc(100% - 40px)',
            minHeight: '100vh',
            zIndex: 1
        },
        '& .title': {
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            '& p': {
                fontSize: 18,
            },
            '& span': {
                cursor: 'pointer',
                color: '#112386',
                fontSize: 14,
                marginLeft: 15,
                '&:hover': {
                    textDecoration: 'underline'
                }

            }
        }
    },
    note: {
        padding: '10px 30px 15px',
        margin: 0,
        color: '#000000a3',
        fontSize: 14
    },
    textField: {
        borderRadius: 6
    }
}));

const CreateLink = ({ handleLink, linkDetails, setLinkDetails, setLoading }) => {
    const classes = useStyles();
    const [link, setLink] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const isValidURL = (myURL) => {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + //port
            '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i');
        return pattern.test(myURL);
    }

    const handleOnChange = (e) => {
        const val = e.target.value
        setLink(val);
        if (isValidURL(val.trim())) setOpenPreview(true);
    };

    const handleCreateLink = async () => {
        try {
            const response = await fetch(`https://rlp-proxy.herokuapp.com/v2?url=${link}`);
            const json = await response.json();
            if (json.metadata) handleLink({ ...json.metadata })
            else {
                enqueueSnackbar('Please enter valid link or url.', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }

        }
        catch (error) {
            setLinkDetails(null)
            enqueueSnackbar('Please enter valid link or url.', {
                variant: 'error',
                preventDuplicate: true
            });
        }

    }

    return (
        <div style={{ backgroundColor: '#FFF', width: '100%', display: 'flex' }}>
            <div className={classes.root} style={{ width: openPreview && '60%' }}>
                <div className={classes.titleRoot}>
                    <p>Add a link</p>
                    {!openPreview && <span className="submitBtn" variant="text" onClick={() => setOpenPreview(true)}>Preview</span>}
                </div>
                <div className={classes.content}>
                    <TextField
                        id="outlined"
                        fullWidth
                        placeholder="Type/Paste URL"
                        multiline
                        variant="outlined"
                        rows={3}
                        rowsMax={10}
                        value={link}
                        onChange={(e) => handleOnChange(e)}
                        InputProps={{ classes: { root: classes.textField } }}
                    // onBlur={(e) => handleTextOnBlur()}
                    />
                </div>
            </div>
            {
                openPreview &&
                <div className={classes.previewRoot}>
                    <div className="title" >
                        <p>Preview</p>
                        <div>
                            <span title="Close Preview" onClick={() => setOpenPreview(false)}>Close</span>
                            <span onClick={() => handleCreateLink()}>Next</span>
                        </div>
                    </div>
                    <div style={{ maxHeight: 500 }}>
                        {link && < LinkPreview url={link} width='100%' fallbackImageSrc={noPreview} />}
                    </div>
                </div>
            }
        </div >
    );

}
const mapDispatchToProps = (dispatch) => ({
    // createPoll: (options) => dispatch(createPoll(options)),
    setLoading: (value) => dispatch(setLoading(value)),
});
export default connect(null, mapDispatchToProps)(CreateLink);